@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
}

.fileInput {
  display: contents;

  & > :global(.MuiPaper-root) {
    background: transparent;
    border: none;
    padding: 0 !important;
    gap: utils.spacing(1) !important;

    & > :first-child {
      display: none;
    }

    & :global(.MuiPaper-root) {
      border: none;
    }
  }
}

.documentsSection {
  display: flex;
  flex-direction: column-reverse;
  gap: utils.spacing(1);
}

.expandedContent {
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(2);
}

.noResultsPaper {
  padding: utils.spacing(1);
}

.noDocumentsPaper {
  margin-top: utils.spacing(-1);
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.comments {
  display: flex;
  flex-direction: column;
}

.conditions {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  padding: utils.spacing(2, 2, 0, 2);
}

.header {
  display: flex;
  justify-content: space-between;
  gap: utils.spacing(2);
  align-items: center;
  margin-bottom: utils.spacing(1);
}

.noConditions {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  align-self: center;
  align-items: center;
  padding: utils.spacing(2);

  & > button {
    margin-top: utils.spacing(1);
  }
}

.mainRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
  padding: utils.spacing(0.5);
}


.conditionInfo {
  flex: 1 1 auto;
}

.editButton {
  margin-right: utils.spacing(-1);
  margin-left: utils.spacing(2);
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: utils.spacing(0.25);
}

.infoIcon {
  margin-left: utils.spacing(0.5);
}

.chipsRow {
  display: flex;
  align-items: center;
}

.card {
  display: flex;
  align-items: center;
  width: 100%;
}

.chip {
  border-radius: var(--app-border-radius);
  border: 1px solid var(--app-color_border);
  padding: utils.spacing(0.25, 1);
}

.filledCard {
  background-color: var(--app-color_background);
  padding: utils.spacing(0.5);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);
}

.timelineLoading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: utils.spacing(1);
  padding: utils.spacing(6);
}

.timelineSeparator {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before, &:after {
    content: '';
    width: 1px;
    height: utils.spacing(0.75);
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:before {
    margin-bottom: utils.spacing(-0.25);
  }

  &:after {
    margin-top: utils.spacing(-0.25);
  }
}

.actionCard {
  padding: utils.spacing(1);
}

.actionCardIcon {
  float: left;
  margin-right: utils.spacing(1);
}

.actionCardDate {
  float: right;
  margin-left: utils.spacing(2);
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  padding-top: utils.spacing(1);
}

.actionButton {
  margin-left: auto;
}

.positionRelative {
  position: relative;
}

.positionAbsolute {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}
